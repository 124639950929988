@use "globals" as *;

.sa-visualizer__toolbar {
}

.sa-visualizer__header {
  font-family: $font-family-secondary;
  font-size: $root-font-size;
}

.sa-visualizer__content {
  font-family: $font-family;
  display: inline-block;
  overflow: hidden;
  width: 100%;
}

.sa-visualizer__footer {
  margin-top: 10px;
}

.sa-visualizer__footer-title {
  display: inline-block;
  font-family: $font-family-secondary;
  font-size: $root-font-size;
  color: #404040;
  line-height: 34px;
  vertical-align: middle;
  margin: 0;
}

.sa-visualizer__footer-content {
  margin-top: 10px;
}

.sa-data-loading-indicator-panel {
  width: 100%;
  min-height: 200px;
  position: relative;
}

.sa-data-loading-indicator {
  position: absolute;
  width: 64px;
  height: 64px;
  left: calc((100% - 64px) / 2);
  top: calc((100% - 64px) / 2);
  animation: sa-data-loading-indicator-spinner 1s infinite linear;
}

@keyframes sa-data-loading-indicator-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
