@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Soleil';
  src: local('Soleil'), url(./fonts/Soleil.woff) format('woff');
}

.bookyourexpert {
  font-family: 'Soleil'
}
