.Draggable {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    min-height: 50px;
    flex-shrink: 1;
    appearance: none;
    outline: none;
    border: 0;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    transform: scale(var(--scale, 1));
  }

  &:not(.handle) {
    button {
      touch-action: none;
      cursor: grab;

      &:focus-visible:not(.active &) {
        box-shadow: 0 0 0 3px #4c9ffe;
      }
    }
  }

  &.handle {
    button {
      > svg {
        margin-right: 5px;
      }

      > button {
        margin-right: -10px;
      }
    }
  }

  img {
    width: 140px;
    user-select: none;
    pointer-events: none;
  }

  label {
    display: block;
    flex-shrink: 1;
    padding: 5px;

    font-weight: 500;
    color: #8d8d8d;
    user-select: none;
  }

  &.dragging {
    z-index: 1;

    * {
      cursor: grabbing;
    }
  }
}

@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow);
  }
}
