@import '~react-datetime/css/react-datetime.css';

.Polaris-TopBar__LogoContainer {
  overflow: hidden;
}

.Polaris-Thumbnail {
  box-shadow: none;
  border: none;
}

.Polaris-TopBar-SearchField__Input {
  padding-left: 2rem;
  border: solid rgb(229, 231, 235) 1px;
  border-radius: 3px;
}

.Polaris-Backdrop {
  top: -30px;
}

.Polaris-Thumbnail::after {
  box-shadow: none;
}

.Polaris-Button--primary {
  background-color: var(--p-action-primary);
}

.Polaris-Button--destructive {
  background-color: var(--p-action-critical);
}

.Polaris-Button--plain {
  background-color: transparent;
}

.Polaris-Frame-ContextualSaveBar__Message {
  white-space: break-spaces;
}

.Polaris-DropZone--hasOutline {
  z-index: 0;
}

.Polaris-DatePicker__Day--selected {
  background-color: var(--p-action-primary);
}

.Polaris-Frame__ContextualSaveBar {
  z-index: 600;
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: #06a6fc;
}

.vertical-timeline--add_more:hover {
  cursor: pointer;
}

.vertical-timeline-element-icon svg {
  fill: white;
}

.vertical-timeline-element-date {
  color: black;
}

.fc .fc-toolbar-title {
  font-size: 1.25em;
}

.flex-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.image-tool__caption { display: none; }

.Polaris-DropZone-FileUpload {
  .Polaris-Stack.Polaris-Stack--vertical {
    .Polaris-Stack__Item {
      margin: auto;
      padding-bottom: 4px;
    }
  }
}

.Polaris-Frame-ContextualSaveBar__Message {
  font-size: 14px;
}

.flex-item-left {
  padding: 10px;
  padding-left: 24vw;
  padding-right: 5vw;
  flex: 50%;
  margin: auto;
}

.flex-item-right {
  padding: 10px;
  flex: 50%;
  margin: auto;
}

.listEnd {
  margin-bottom: 2rem;
}

.fc-event-main {
  overflow: hidden;
}

.Polaris-TopBar__SecondaryMenu {
  min-width: 9rem;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: rgb(255, 192, 82);
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 1000px) {
  .flex-item-left {
    padding: 0;
    padding-top: 10px;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media (max-width: 800px) {
  .flex-item-left {
    padding: 0;
    padding-top: 10px;
  }

  .flex-container {
    flex-direction: column;
  }
}

h1 {
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 10px;
}

h2 {
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 10px;
}

h3 {
  font-size: 26px;
  line-height: 26px;
  margin-bottom: 10px;
}

h4 {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
}

h5 {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 10px;
}


.react-select {
  input {
    -moz-box-shadow: 0 0 0 rgba(255, 255, 255, 0.15) !important;
    -webkit-box-shadow: 0 0 0 rgba(255, 255, 255, 0.15) !important;
    -goog-ms-box-shadow: 0 0 0 #ffffff !important;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.15) !important;
    focus {
      outline: none !important;
    }
  }
  z-index: 10000000 !important;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.validationflow .react-flow__node {
  width: 150px;
  border-radius: 5px;
  padding: 10px;
  color: #555;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 12px;
}

.validationflow .react-flow__node-customnode {
  background: #e6e6e9;
  border: 1px solid #ddd;
}

.react-flow__node-custominput .react-flow__handle {
  background: #e6e6e9;
}

.validationflow .react-flow__node-custominput {
  background: #fff;

}

.validationflow .react-flow__handle-connecting {
  background: #ff6060;
}

.validationflow .react-flow__handle-valid {
  background: #55dd99;
}

.reset-button {
  button {
    @apply bg-transparent border-none shadow-none p-0 m-0;
    appearance: none;
    background-color: inherit;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

.reset-all {
  all: unset;
  display: revert; /* Reset default display for elements like div, span, etc. */
  box-sizing: border-box;
}

.sv-string-viewer {
  position: relative;
  z-index: 1;
}

.sv-list__filter-icon {
  .sv-svg-icon {
    display: none;
  }
}

input[type="date"]:before {
  content: none;
}

.hidden-on-load {
  display: none !important;
}