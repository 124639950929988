.flex-container {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.nowrap  {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.wrap    {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-item {
  margin: 3px;
}
