.Auth {
  box-sizing: border-box;
  margin: 12rem auto;
  padding: 10px;
  text-align: center;
  width: 400px;

  Button {
    width: 100%;
  }

  img {
    width: 80%;
  }
}

.AppSumo {
  box-sizing: border-box;
  margin: 12rem auto;
  margin-top: 4rem;
  text-align: center;
  width: 400px;

  Button {
    width: 100%;
  }

  img {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .Auth {
    width: 100vw;
  }
}
