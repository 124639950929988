// Variables
@import "variables";

// Component syles
@import "react-star-rating";
@import "react-select";
@import "react-date-picker";

@import "react-draft";

// Form builder styles
@import "form-builder";
@import "form-builder-form";


.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
