.tabs {
  overflow: hidden;
  border: 1px solid #ccc;
  min-height: 43px;
  background-color: #f1f1f1;
}

.tabs button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

.tabs button:hover {
  background-color: #ddd;
}

.tabs button.active {
  background-color: #ccc;
}

.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.data-loading-indicator-panel {
    width: 100%;
    height: 400px;
}
.data-loading-indicator {
    position: relative;
    width: 64px;
    height: 64px;
    left: calc((100% - 64px)/ 2);
    top: calc((100% - 64px)/ 2);
    animation: data-loading-indicator-spinner 1s infinite linear;
}
@keyframes data-loading-indicator-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}