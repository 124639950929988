.spg-search-editor_input {
  background: none;
}

.spg-search-editor_container {
  background-color: white;
  width: 100%;
}

.spg-search-editor_input {
  background: none !important;
  width: 100%;
}