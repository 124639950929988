$panelWidth: 100%;

.panel {
  width: $panelWidth;
  min-height: 500px;
  position: relative;
  overflow: hidden;
}

.content {
  position: relative;
  background-color: #f8f8f8;
  width: $panelWidth;
  // float: right;
  height: 300px;
  // z-index: 1;
  border: 1px solid black;
}

.contentTop {
  position: relative;
  // z-index: 1;
  background-color: #f8f8f8;
  width: $panelWidth;
  // float: left;
  height: 200px;
  border: 1px solid black;
}

.contentTopText {
  // display: flex;
  height: 200px;
  padding: 20px;
  text-align: right;
  align-items: right;
}

.lock {
  border-radius: 50%;
  padding: 10px;
  border: 1px solid rgb(46, 46, 46); /* inner border */
  background-color: #eeecec;
  position: absolute;
  height: 67px;
  // place-self: center;
  left: 42%;
  top: -35px;
}

.lock:after {
  border-radius: 50%;
  // border: 2px solid rgb(80, 80, 80);
  background-color: #b1aeae;
  content: "";
  position: absolute;
  z-index: -1;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
}

.lock:before {
  border-radius: 50%;
  // border: 2px solid rgb(80, 80, 80);
  // background-color: #107fb3;
  border: 1px solid #b1aeae;
  box-shadow: 0px 0px 0px 6px #0099ff inset;

  content: "";
  position: absolute;
  z-index: -10;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
}

.lock[error='1'] {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

.lock[error='1']:before {
  box-shadow: 0px 0px 0px 6px #ff3c00 inset;
}

@keyframes shake {
  0% { transform: translate(2px, 2px) rotate(0deg); }
  10% { transform: translate(-2px, -1px) rotate(-1deg); }
  20% { transform: translate(-6px, 0px) rotate(1deg); }
  30% { transform: translate(2px, 1px) rotate(0deg); }
  40% { transform: translate(2px, -2px) rotate(1deg); }
  50% { transform: translate(-2px, 4px) rotate(-1deg); }
  60% { transform: translate(-3px, 2px) rotate(0deg); }
  70% { transform: translate(1px, 2px) rotate(-1deg); }
  80% { transform: translate(-2px, -2px) rotate(1deg); }
  90% { transform: translate(2px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.login {
  top: 100px;
  width: 50%;
  left: 25%;
  justify-content: center;
  text-align: center;
  z-index: 300;
  height: 300px;
  position: absolute;

  .loginDetail {
    padding: 3px;
  }
}



@media only screen and (max-width: 600px) {
  .login {
    top: 100px;
    width: 80%;
    left: 10%;
    justify-content: center;
    text-align: center;
    z-index: 300;
    height: 300px;
    position: absolute;
  }

}
